<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-img
        max-width="675"
        class="mx-auto"
        style="margin-bottom: 50px"
        :src="require('@/assets/icosahedron.png')"
      />
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-sync',
          title: 'HSI Buy-Back',
          text: 'Sell HSI HEX stakes to the Icosa contract, get paid in ICSA. The HSI will be borrowed against by the contract and sent to auction. Yes this means you have the option to rebuy your stakes in said auction.',
        },
        {
          icon: 'mdi-lock-outline',
          title: 'Stake HDRN',
          text: 'Stake HDRN for ICSA, the daily yield is determined by the amount of daily HDRN burnt within the Hedron contract. There are minimum stake lengths associated with stake size.',
        },
        {
          icon: 'mdi-lock-open-outline',
          title: 'Stake ICSA',
          text: 'Stake ICSA for both HDRN and ICSA. Like HDRN staking, ICSA yield comes from daily burnt HDRN. HDRN yield comes from borrowed HSI HEX stakes and EES penalties collected by the contract over time.',
        },
        {
          icon: 'mdi-account-group-outline',
          title: 'We Are All the SA',
          text: 'Enter into a private staking pool which generates yield in ICSA. This is a time limited entry event. Once the pool closes no one can enter again; as stakes end yield increases for all other stakers.',
        },
      ],
    }),
  }
</script>
